<template>
  <div data-aos="fade-up" data-aos-once="true" class="">
    <nuxt-link :to="item?.url"
               @click="trackingNews(category_title, item)"
               class="block h-full bg-white">
      <div
          class="flex justify-between w-full gap-1 p-5 lg:p-6 xl:p-8 w-full rounded-xl cursor-pointer p-5 lg:p-6 xl:p-8 hover:bg-primary/10 bg-shadow duration-200 h-full"
      >
        <div class="flex flex-col gap-2 w-full">
          <p v-if="!loading" class="text-bw-11 text-sm sm:text-15px xl:text-lg font-semibold line-clamp-2">
            {{ item?.title }}
          </p>
          <div class="text-[#9C9C9CE5]/90">
            <div v-if="!loading" class="flex items-center gap-2">
              <span class="i-ic:outline-calendar-today w-3.5 h-3.5"></span>
              <span class="text-xs xl:text-sm">{{ item?.date_created }}</span>
            </div>
          </div>
        </div>
        <span
            class="transform i-ic:sharp-chevron-right text-xl xl:text-2xl text-bw-11/80 duration-200 flex-none"
        ></span>
      </div>
    </nuxt-link>
  </div>
</template>

<script setup lang="ts">
import {FAQS_EVENT_ID, useEventTracking} from "../../../composables/ackee/event";

defineProps({
  item: {
    type: Object
  },
  category_title: {
    type: String
  },
  loading: {
    type: Boolean
  }
})

const trackingNews = (category_title: any, dataItem: any) => {
  useEventTracking(FAQS_EVENT_ID, `${category_title} - ${dataItem?.translations?.title}`)
}
</script>

<style scoped>
.bg-shadow {
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.1);
}
</style>
