<template>
  <section class="">
    <div class="container mt-5 mb-10 md:mb-20 md:mt-15 xl:mt-20 xl:mb-23">
      <h1
          data-aos="fade-right"
          data-aos-once="true"
          v-show="!pending"
          class="text-2xl md:text-4xl xl:text-56px font-[550] leading-12 xl:leading-70px text-black line-clamp-2 font-semibold"
      >
        {{ dataRender?.category?.title }}
      </h1>
      <div class="flex flex-col lg:flex-row justify-between lg:items-end mt-4 xl:mt-6 gap-5">
        <p
            data-aos="fade-up"
            data-aos-once="true"
            class="text-sm xl:text-base text-black/60 max-w-sm line-clamp-5"
        >
          {{ dataRender?.category?.intro }}
        </p>
        <!-- <div v-show="pending" class="w-full max-w-lg h-full space-y-3">
            <GlobalSkeleton :loading="pending" class="w-6/10 h-10" />
            <GlobalSkeleton :loading="pending" class="w-full h-15" />
        </div> -->
        <!-- <GlobalSkeleton :loading="pending" class="w-full max-w-sm h-12" /> -->

        <form v-show="!pending" @submit.prevent="searchCategoryFaq" class="w-full lg:max-w-sm">
          <div
              data-aos="fade-left"
              data-aos-once="true"
              class="relative flex items-center input-search-faq rounded-xl bg-shadow"
          >
            <input
                v-model="searchTitle"
                type="text"
                class="relative bg-white rounded-xl pl-12 pr-2 py-3.5 w-full border-none focus:ring-0 hover:bg-white/90 shadow-lg text-sm"
                :placeholder="$t('FIND_THE_RIGHT_QUESTION_2')"
            />
            <button v-if="!activeSearch" class="absolute left-3.5 text-primary hover:text-accent-01">
              <div class="i-ic:twotone-search w-6 h-6 duration-400 text-primary"></div>
            </button>
            <div v-else class="absolute loaderForm left-3.5"></div>
          </div>
        </form>
      </div>
      <div class="w-full h-1px bg-bw-08 mb-5 mt-5 xl:mb-20"></div>
      <p v-if="dataRender?.category.faqs && dataRender?.category.faqs.length === 0" class="text-lg text-bw-04">
        {{ $t('NO_DATA') }}
      </p>
      <div
          v-if="dataRender?.category.faqs && dataRender?.category.faqs?.length > 0"
          class="grid grid-cols-1 md:grid-cols-2 gap-4 lg:gap-7.5"
      >
        <wrapper-content v-for="item in dataRender?.category.faqs.slice(0, LIMIT_CATEGORY)"
                         :item="item"
                         :category_title="dataRender?.category?.title"/>
      </div>
      <button
          v-if="dataRender?.category?.faqs.length > 10"
          @click="addMoreCategory"
          class="flex items-center w-fit gap-1 btn-effect text-white bg-primary font-semibold uppercase text-sm px-11.5 rounded-30px py-4.5 mt-10 mx-auto"
      >
        <span>{{ t('SHOW_MORE') }}</span>
        <span class="i-ic:twotone-keyboard-arrow-down text-white text-xl"></span>
      </button>
    </div>
  </section>
</template>

<script setup lang="ts">
import WrapperContent from './components/__WrapperContentCategories.vue'

import {useFaq} from '../../composables/faq/index'
import {useGetLangCurrent} from '../../stores/useLanguageMenu'

const {t} = useI18n()
const {getFaqsByCategoryId, getFaqsByQuery} = useFaq()
const route = useRoute()
const dataFaqDefault = ref()

const _idCategory = route.params?.id?.split('-').pop()
let LIMIT_CATEGORY = ref(10)
// const { data: dataRender, pending }: any = await getFaqsByCategoryId(_idCategory, route.params.langCode, {
//     limit: LIMIT_CATEGORY.value
// })
// dataFaqDefault.value = dataRender.value

let dataRender: any = await getFaqsByCategoryId(_idCategory, route.params.langCode, {
  limit: LIMIT_CATEGORY.value
})
dataFaqDefault.value = dataRender

const activeSearch = ref(false)
const addMoreCategory = async () => {
  dataRender.model = getFaqsByCategoryId(_idCategory, route.params.langCode, {
    limit: LIMIT_CATEGORY.value + 10
  })
  LIMIT_CATEGORY.value = LIMIT_CATEGORY.value + 10
}
const lang = useRoute().params.lang

useDirectusCollectionSeo('faq_categories', {
  ...dataRender?.category,
  seo_detail: {
    ...dataRender?.category?.seo_detail
  }
})

const searchTitle = ref()
const searchCategoryFaq = async () => {
  activeSearch.value = true
  if (searchTitle.value) {
    // const { data: dataSearch } = await getFaqsByQuery(useRoute().params.langCode, {
    const dataSearch = await getFaqsByQuery(useRoute().params.langCode, {
      filter: {
        _and: [
          {
            status: {
              _eq: 'published'
            }
          },
          {category: {_eq: useRoute().params.id}},
          {
            category: {
              status: {_eq: 'published'}
            }
          },
          {
            translations: {
              title: {
                _icontains: searchTitle.value
              }
            }
          }
        ]
      },
      limit: 10
    })
    if (dataSearch.faqs.length > 0) {
      activeSearch.value = false

      dataRender.category.faqs = dataSearch.faqs
    } else {
      activeSearch.value = false

      dataRender.category.faqs = []
    }
  } else {
    activeSearch.value = false
    dataRender = await getFaqsByCategoryId(_idCategory, route.params.langCode, {
      limit: LIMIT_CATEGORY.value
    })
  }
}
watch(
    () => searchTitle.value,
    (newValue) => {
      if (newValue == '') {
        dataRender.value.faqs = dataFaqDefault.value.category.faqs
      }
    }
)
const store = useGetLangCurrent()
onMounted(async () => {
  store.lang_switch = dataRender.category.lang_switch
})
</script>

<style scoped>
.bg-shadow {
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.1);
}
</style>
